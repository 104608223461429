import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { CustomerAddComponent } from './pages/customer-add/customer-add.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';

const routes: Routes = [
  { path: 'add', component: CustomerAddComponent },
  { path: 'list', component: CustomerListComponent },
  { path: 'edit/:id', component: CustomerAddComponent },
  { path: '', redirectTo: 'list', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersMgmtRoutingModule { }
