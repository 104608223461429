
<div class="nk-block-head nk-block-head-sm">
  <div class="nk-block-between">
    <div class="nk-block-head-content">
      <h3 class="nk-block-title page-title">{{ this.operationTitle }}</h3>
      <div class="nk-block-des text-soft">
        <p>{{ this.operationSubtitle }}</p>
      </div>
    </div><!-- .nk-block-head-content -->
  </div><!-- .nk-block-between -->
</div><!-- .nk-block-head -->

<lockbin-alert [isFilled]="false" [dismissible]="true"></lockbin-alert>
<!-- NOTE form -->
<form [formGroup]="formGroup" autocomplete="on">
  <div class="nk-block">
      <div class="card card-bordered">
          <div class="card-inner-group">
              <div class="card-inner">
                  <div class="nk-block-head">
                      <div class="nk-block-head-content">
                          <h5 class="title nk-block-title">Datos Básicos</h5>
                          <!-- <p>Add common infomation like Name, Email etc </p> -->
                      </div>
                  </div>
                  <div class="nk-block">
                      <div class="row gy-4">
                          <div class="col-xxl-5 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="name">Nombre</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="name" placeholder="Nombre"
                                        formControlName="name"
                                        [ngClass]="{ 'is-invalid' : hasErrorDueToCustomerNameExists() && formGroup.controls.name.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.name.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                        <div class="invalid-feedback" *ngIf="hasErrorDueToCustomerNameExists()">
                                          {{ 'FIELD_NAME_EXISTS' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                          <div class="col-xxl-3 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="code">Código</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="code" placeholder="Código"
                                        formControlName="code"
                                        [ngClass]="{ 'is-invalid' : formGroup.controls.code.errors && formGroup.controls.code.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.code.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                          <div class="col-xxl-3 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="fiscalId">Id. Fiscal</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="fiscalId" placeholder="Identificador Fiscal"
                                        formControlName="fiscalId"
                                        [ngClass]="{ 'is-invalid' : formGroup.controls.fiscalId.errors && formGroup.controls.fiscalId.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.fiscalId.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                          <div class="col-xxl-3 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="address">Dirección</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="address" placeholder="Dirección"
                                        formControlName="address"
                                        [ngClass]="{ 'is-invalid' : formGroup.controls.address.errors && formGroup.controls.address.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.address.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                          <div class="col-xxl-3 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="postalCode">CP</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="postalCode" placeholder="Cód. Postal"
                                        formControlName="postalCode"
                                        [ngClass]="{ 'is-invalid' : formGroup.controls.postalCode.errors && formGroup.controls.postalCode.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.postalCode.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                          <div class="col-xxl-3 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="phone1">Tel. 1</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="phone1" placeholder="Teléfono"
                                        formControlName="phone1"
                                        [ngClass]="{ 'is-invalid' : formGroup.controls.phone1.errors && formGroup.controls.phone1.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.phone1.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                          <div class="col-xxl-3 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="phone2">Tel. 2</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="phone2" placeholder="Teléfono alternativo"
                                        formControlName="phone2"
                                        [ngClass]="{ 'is-invalid' : formGroup.controls.phone2.errors && formGroup.controls.phone2.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.phone2.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                          <div class="col-xxl-3 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="web">Web</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="web" placeholder="Web"
                                        formControlName="web"
                                        [ngClass]="{ 'is-invalid' : formGroup.controls.web.errors && formGroup.controls.web.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.web.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                          <div class="col-xxl-3 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="email">Correo Electrónico</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="email" placeholder="Correo Electrónico"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid' : formGroup.controls.email.errors && formGroup.controls.email.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.email.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                          <div class="col-xxl-3 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="customerType">Tipo de Cliente</label>
                                  <div class="form-control-wrap">
                                      <select class="form-control"
                                        formControlName="customerType">
                                            <option value=""> -- Seleccione Tipo de Cliente -- </option>
                                            <option *ngFor="let type of customerTypes"
                                                [value]="type.value"> {{ type.name }}
                                            </option>
                                        </select>

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.customerType.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->

                          <div class="col-xxl-3 col-md-4">
                            <lockbin-custom-autocomplete
                              id="customAutocompleteCity"
                              #customAutocompleteCity
                              (onSelectedItem)="doSelectItemAutocomplete($event)"
                              [searcher]="searcher$"
                              labelInputText= "Ciudad"
                              placeholderText= "Buscar ciudad..."
                              fieldToFilter= "name"
                              fieldToShow= "name">
                            </lockbin-custom-autocomplete><!--custom autocomplete-->
                          </div><!--col-->
                      </div><!--row-->
                  </div>
              </div><!-- .card-inner -->

              <div class="card-inner">
                  <div class="nk-block">
                      <div class="row gy-4">
                          <div class="col">

                            <div class="d-flex justify-content-between">
                              <button (click)="onClickSubmit()"
                                class="btn btn-primary"
                                [lockbinButtonLoading]="this.formGroup.pending || this.storeService.getIsLoading()"
                                [disabled]="formGroup.pending || formGroup.invalid">
                                <span>{{ 'BTN_SAVE' | translate }}</span>
                                <em class="icon ni ni-save"></em>
                              </button>
                              <button (click)="goBack()" class="btn btn-outline-light bg-white">
                                <em class="icon ni ni-arrow-left"></em>
                                <span>{{ 'BTN_BACK' | translate }}</span>
                              </button>
                            </div>

                          </div>
                      </div><!--row-->
                  </div>
              </div><!-- .card-inner -->
          </div>
          <div *ngIf="formGroup.invalid">
            <div *ngIf="formGroup.controls.name.errors && formGroup.controls.name.touched">
              {{ 'FIELD_REQUIRED' | translate }} ---
            </div>
          </div>
      </div><!-- .card -->

  </div><!-- .nk-block -->
</form>

<div class="code-debug" *ngIf="!environment.production">
  <code class="debug">
    <p>Form Valid: {{ formGroup.valid }}</p>
    <p>Form Invalid: {{ formGroup.invalid }}</p>
    <p>Form Status: {{ formGroup.status }}</p>
    <p>Form Pending: {{ formGroup.pending }}</p>
    <p>Form errors</p>

    <pre>{{ formGroup.errors | json }}</pre>

    <p>Nombre</p>
    <pre>{{ formGroup.controls.name.value | json }}</pre>
    <pre>{{ formGroup.controls.name.errors | json }}</pre>

    <p>Email</p>
    <pre>{{ formGroup.controls.code.errors | json }}</pre>

    <p>CEE</p>
    <pre>{{ formGroup.controls.fiscalId.value | json }}</pre>
    <pre>{{ formGroup.controls.fiscalId.errors | json }}</pre>
  </code>
</div>
