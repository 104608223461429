// Angular modules
import { Injectable } from '@angular/core';

import { DataListConfigurerHelper } from '@helpers/datalist-configurer.helper';
import { Customer } from '../models';
import {
  DataListOperation,
  DataListHeader,
  DataListHeaderItem,
  ResponsiveBreakpoints,
  DataListPagination,
  DataListRowOperationAlias,
  DataList,
  DataListItem
} from '@models/index';

@Injectable()
export class CustomerDataListConfigurerHelper extends DataListConfigurerHelper<Customer>{

  public getDefaulDataList(): DataList<DataListItem<Customer>> {

    const toret: DataList<DataListItem<Customer>> = new DataList();

    toret.searchPlaceholder= 'Buscar por nombre';
    toret.name = '------TITULO-----------';
    toret.useCheckbox = true;
    toret.useSearch = true;

    return toret;
  }

  public getDefaulDataListHeader(): DataListHeader {
    const toret: DataListHeader = new DataListHeader();

    toret.canToggleItems = true;
    toret.pagination = new DataListPagination();
    toret.headers = this.getDefaultDataListHeaderItems();

    return toret;
  }

  public getDefaultDataListHeaderItems(): DataListHeaderItem[] {
    const toret: DataListHeaderItem[] = [
      {
        alias: 'name',
        title: 'Nombre',
        sortable: true,
        visible: true,
      },
      {
        alias: 'fiscalId',
        title: 'Id. Fiscal',
        sortable: true,
        visible: true,
      },
      {
        alias: 'address',
        title: 'Dirección',
        sortable: true,
        visible: true,
      },
      {
        alias: 'postalCode',
        title: 'CP',
        sortable: true,
        visible: false,
      },
      {
        alias: 'phone1',
        title: 'Tel. 1',
        sortable: true,
        visible: true,
      },
      {
        alias: 'phone2',
        title: 'Tel. 2',
        sortable: true,
        visible: false,
      },
      {
        alias: 'email',
        title: 'Correo-e',
        sortable: true,
        visible: true,
      },
      {
        alias: 'web',
        title: 'Web',
        sortable: true,
        visible: false,
      },
      {
        alias: 'latitude',
        title: 'Latitud',
        sortable: true,
        visible: false,
      },
      {
        alias: 'longitude',
        title: 'Longitud',
        sortable: true,
        visible: false,
      },
      {
        alias: 'city',
        title: 'Ciudad',
        sortable: true,
        visible: true,
      },
    ];

    return toret;
  }

  public getDefaultDataListOperations(): DataListOperation[] {
    return [
      {
        alias: DataListRowOperationAlias.ViewDetails,
        icon: 'eye',
        title: 'Ver detalle',
      },
      {
        alias: DataListRowOperationAlias.Edit,
        icon: 'pen',
        title: 'Modificar',
      },
      {
        alias: DataListRowOperationAlias.Delete,
        icon: 'na',
        title: 'Deshabilitar',
      },
    ];
  }

  public getDefaultBulkOperations(): DataListOperation[] {
    return [
        // {
        // "alias": DataListRowOperationAlias.Email,
        //   "title": "Send Email",
        // },
        // {
        //   "alias": DataListRowOperationAlias.ChangeGroup,
        //   "title": "Change Group",
        // },
        // {
        //   "alias": DataListRowOperationAlias.Suspend,
        //   "title": "Suspend User",
        // },
        {
          "alias": DataListRowOperationAlias.Delete,
          "title": "Deshabilitar",
        },
      ]
  }

}
