// Angular modules
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

// External modules
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '@services/store.service';
import { NGXLogger } from 'ngx-logger';

// Helpers
import { CustomerFormGroupHelper } from '@customers/customers-mgmt/helpers/customer-form-configurer.helper';

// Models
import { Customer } from '@customers/customers-mgmt/models';

// Modal
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Base class
import { BaseQuickviewComponent } from '@pages/base/base-quickview.component';
import { CustomerType } from '@customers/enums/customer.enum';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'lockbin-customer-quickview',
  templateUrl: './customer-quickview.component.html',
  styles: [
  ]
})
export default class CustomerQuickviewComponent extends BaseQuickviewComponent<Customer> implements OnInit {

  @Input() data: Customer | undefined;
  @Output() submitData: EventEmitter<any> = new EventEmitter();
  @Output() submitClose: EventEmitter<null> = new EventEmitter();

  customerTypeName = '';


  // -------------------------------------------------------------------------------
  // NOTE Constructor & Init -------------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override router: Router,
    protected override logger: NGXLogger,
    protected override translateService: TranslateService,
    protected override activeModal: NgbActiveModal,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,

  ) {

    super(location, titleService, storeService, router, logger, translateService, activeModal, activatedRoute, eventBusService);

  }

  override ngOnInit(): void {

    if (!this.data) return;

    this.entity = this.data;

    this.customerTypeName = this.getCustomerTypeName(this.entity.customerTypeId);

    this.setupRoutes();

  }

  /**
   * Set routes to list/edit after page operation (add or edit) is done
   */
  private setupRoutes() {
    this.routeToEdit = '/clientes/gestion-clientes/edit/';
  }


  private getCustomerTypeName(customerTypeId: number): string {

    const enumKeys = Object.keys(CustomerType).filter((key: any) => !isNaN(Number(CustomerType[key])));
    const enumValues = Object.values(CustomerType);

    const index = enumValues.indexOf(customerTypeId);
    if (index !== -1) {
      return enumKeys[index];
    }

    return '';
  }



}
