
<div class="nk-block">
  <div class="row gy-4 mb-3">

    <div class="col-xxl-9 col-md-6 col-lg-9">
      <div class="form-group">
        <label class="form-label" for="name">Nombre</label>
        <div class="form-control-wrap">
            <input type="text" id="name" name="name" class="form-control form-control-readonly" readonly value="{{ entity.name }}">
        </div>
      </div>
    </div><!--col-->

    <div class="col-xxl-3 col-md-6 col-lg-3">
      <div class="form-group">
        <label class="form-label" for="code">Código</label>
        <div class="form-control-wrap">
            <input type="text" id="code" name="code" class="form-control form-control-readonly" readonly value="{{ entity.code }}">
        </div>
      </div>
    </div><!--col-->

    <div class="col-xxl-6 col-md-6 col-lg-6">
      <div class="form-group">
        <label class="form-label" for="fiscalId">Ident. Fiscal</label>
        <div class="form-control-wrap">
            <input type="text" id="fiscalId" name="fiscalId" class="form-control form-control-readonly" readonly value="{{ entity.fiscalId}}">
        </div>
      </div>
    </div><!--col-->

    <div class="col-xxl-6 col-md-6 col-lg-6">
      <div class="form-group">
        <label class="form-label" for="customerType">Tipo de cliente</label>
        <div class="form-control-wrap">
            <input type="text" id="customerType" name="customerType" class="form-control form-control-readonly" readonly value="{{ customerTypeName }}">
        </div>
      </div>
    </div><!--col-->

    <div class="col-xxl-12 col-md-12 col-lg-12">
      <div class="form-group">
        <label class="form-label" for="email">Email</label>
        <div class="form-control-wrap">
            <input type="text" id="email" name="email" class="form-control form-control-readonly" readonly value="{{ entity.email }}">
        </div>
      </div>
    </div><!--col-->

    <div class="col-xxl-4 col-md-4 col-lg-4">
      <div class="form-group">
        <label class="form-label" for="phone1">Tel. 1</label>
        <div class="form-control-wrap">
            <input type="text" id="phone1" name="phone1" class="form-control form-control-readonly" readonly value="{{ entity.phone1 }}">
        </div>
      </div>
    </div><!--col-->

    <div class="col-xxl-4 col-md-4 col-lg-4">
      <div class="form-group">
        <label class="form-label" for="phone2">Tel. 2</label>
        <div class="form-control-wrap">
            <input type="text" id="phone2" name="phone2" class="form-control form-control-readonly" readonly value="{{ entity.phone2 }}">
        </div>
      </div>
    </div><!--col-->


  </div><!--row-->
</div><!--nk-block-->

<div class="nk-block">
  <div class="row gy-2">
    <div class="col-xxl-6 col-md-6">
      <div class="form-group">
        <button (click)="gotoEditPage($event)" class="btn btn-primary">
          {{ 'BTN_EDIT' | translate }}
        </button>
      </div>
    </div><!--col-->
  </div><!--row-->
</div><!--block-->


