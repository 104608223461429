import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Internal Modules
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomersMgmtRoutingModule } from './customers-mgmt-routing.module';

// Components
import { CustomerAddComponent } from './pages/customer-add/customer-add.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import CustomerQuickviewComponent from './pages/customer-quickview/customer-quickview.component';


@NgModule({
  declarations: [
    CustomerAddComponent,
    CustomerListComponent,
    CustomerQuickviewComponent
  ],
  imports: [
    CommonModule,

    // Internal Modules
    CustomersMgmtRoutingModule,
    SharedModule
  ],
  exports: [
    CustomerListComponent,
    CustomerAddComponent,
  ]
})
export class CustomersMgmtModule { }
